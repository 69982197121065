import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Menu,
  X,
  ChevronDown,
  ChevronRight,
  User,
  Gamepad,
  Shield,
  Headphones,
  HandHelpingIcon,
  Phone,
  BookOpen,
  Layout,
  Activity,
} from 'lucide-react';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mobileActiveDropdown, setMobileActiveDropdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const navLinks = [
    { name: 'Home', href: '/' },
    {
      name: 'Game Server',
      href: '/games',
      icon: Gamepad,
      dropdown: [
        { name: 'Minecraft', href: '/minecraft' },
        { name: 'FiveM', href: '/fivem' },
        { name: 'Garry\'s Mod', href: '/gmod' },
        ],
    },
    {
      name: 'Services',
      href: '#',
      icon: Shield,
      dropdown: [
        { name: 'Games/Bots Hosting', href: '/games' },
        { name: 'Web Hosting', href: '/webhosting' },
        { name: 'Discord Bot Hosting', href: '/discord' },
      ],
    },
    { name: 'Partners', href: '/partners', icon: HandHelpingIcon },
    { name: 'Ticket Support', href: 'https://store.reedrouxhosting.com/tickets', icon: Headphones },
  ];

  const quickLinks = [
    { name: 'Knowledgebase', href: '/knowledgebase', icon: BookOpen },
    { name: 'Client Area', href: 'https://store.reedrouxhosting.com/', icon: Layout },
    { name: 'Status Page', href: 'https://status.reedrouxhosting.us/', icon: Activity },
    { name: 'Game Panel', href: 'https://panel.reedrouxhosting.com/', icon: Gamepad },
  ];

  const handleNavigation = (href) => {
    if (href.startsWith('http')) {
      window.open(href, '_blank', 'noopener noreferrer');
    } else {
      navigate(href);
      setIsOpen(false);
      setMobileActiveDropdown(null);
    }
  };

  return (
    <>
      {/* Overlay for mobile menu */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/80 z-40 md:hidden" 
          onClick={() => setIsOpen(false)}
        />
      )}
      
      <nav
        className={`fixed w-full top-0 z-50 transition-all duration-300 ${
          scrolled
            ? 'bg-gray-900 border-b border-gray-800'
            : 'bg-gray-900 border-gray-800'
        }`}
      >
        {/* Top Bar */}
        <div className="hidden md:block bg-gray-800 text-gray-300 py-1 border-b border-gray-700">
          <div className="max-w-7xl mx-auto px-4 flex justify-between items-center text-sm">
            <div className="flex items-center space-x-4">
              <a href="tel:+1234567890" className="hover:text-blue-400 transition-colors flex items-center">
                <Headphones size={14} className="mr-2" />
                Support: +1 (234) 567-890
              </a>
            </div>
            <div className="flex items-center space-x-6">
              {quickLinks.map(({ name, href }) => (
                href.startsWith('http') ? (
                  <a
                    key={name}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-400 transition-colors text-sm font-medium"
                  >
                    {name}
                  </a>
                ) : (
                  <Link
                    key={name}
                    to={href}
                    className="hover:text-blue-400 transition-colors text-sm font-medium"
                  >
                    {name}
                  </Link>
                )
              ))}
            </div>
          </div>
        </div>

        {/* Main Navbar */}
        <div className="max-w-7xl mx-auto px-4">
       <div className="flex justify-between items-center h-16">
       <div className="flex items-center">
           {/* Logo und Link */}
         <Link to="/" className="flex items-center space-x-2">
         <img
           src="https://avatars.githubusercontent.com/u/185548705?s=200&v=4"
          alt="RHVC Hosting Logo"
          className="h-10 w-10"
            />
          <span className="text-3xl font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-transparent bg-clip-text">
          RHVC Hosting
             </span>
         </Link>
         </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-2">
              {navLinks.map((link) => (
                <div
                  key={link.name}
                  className="relative group"
                >
                  <button
                    onClick={() => handleNavigation(link.href)}
                    className="px-4 py-2 rounded-lg text-gray-300 group-hover:text-white group-hover:bg-gray-800 transition-all flex items-center space-x-2 font-bold"
                  >
                    {link.icon && <link.icon size={16} />}
                    <span>{link.name}</span>
                    {link.dropdown && <ChevronDown size={14} className="transform group-hover:rotate-180 transition-transform duration-200" />}
                  </button>

                  {link.dropdown && (
                    <div 
                      className="absolute top-full left-0 w-52 bg-gray-800 rounded-lg shadow-xl py-2 mt-1 border border-gray-700
                               opacity-0 invisible group-hover:opacity-100 group-hover:visible 
                               transition-all duration-200 transform translate-y-2 group-hover:translate-y-0"
                    >
                      <div className="absolute h-4 w-full -top-4 bg-transparent"></div>
                      {link.dropdown.map((item) => (
                        <button
                          key={item.name}
                          onClick={() => handleNavigation(item.href)}
                          className="block w-full text-left px-4 py-2 text-gray-300 hover:bg-gray-700 hover:text-white transition-all font-bold"
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}

              {/* Action Buttons */}
              <div className="flex items-center space-x-2 ml-4">
                <a
                  href="https://store.reedrouxhosting.com/login"
                  className="bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 text-white px-6 py-2 rounded-lg 
                  transition-all hover:scale-105 font-medium flex items-center group"
                  aria-label="Client Area"
                >
                  <User size={16} className="mr-2 transform transition-transform group-hover:scale-110" />
                  Client Area
                </a>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-800 transition-all"
              >
                {isOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {/* Mobile Navigation Menu */}
          <div
            className={`fixed inset-y-0 right-0 w-full max-w-sm bg-gray-900 shadow-2xl transition-transform duration-300 ease-in-out md:hidden ${
              isOpen ? 'translate-x-1' : 'translate-x-full'
            }`}
            style={{
              top: 0,
              bottom: 0,
              zIndex: 999,
            }}
          >
            {/* Mobile Menu Header */}
            <div className="sticky top-0 flex items-center justify-between p-4 bg-gray-900 border-b border-gray-800">
              <span className="text-xl font-bold text-white">Menu</span>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 rounded-lg text-gray-300 hover:text-white hover:bg-gray-800 transition-all"
              >
                <X size={24} />
              </button>
            </div>

            {/* Mobile Menu Content */}
            <div className="overflow-y-auto h-[calc(100vh-4rem)]">
              {/* Quick Contact */}
              <div className="p-4 bg-gray-800">
                <a
                  href="tel:+1234567890"
                  className="flex items-center space-x-3 text-gray-300 hover:text-white"
                >
                  <Phone size={20} />
                  <span className="font-medium">+1 (234) 567-890</span>
                </a>
              </div>

              {/* Nav Links */}
              <div className="p-4">
                {navLinks.map((link) => (
                  <div
                    key={link.name}
                    className="relative"
                  >
                    <button
                      onClick={() => handleNavigation(link.href)}
                      className="flex w-full items-center justify-between px-4 py-3 text-gray-300 hover:bg-gray-800 hover:text-white font-medium"
                    >
                      <span className="flex items-center space-x-2">
                        {link.icon && <link.icon size={16} />}
                        <span>{link.name}</span>
                      </span>
                      {link.dropdown && (
                        <ChevronRight
                          size={16}
                          className={`transition-transform duration-200 ${
                            mobileActiveDropdown === link.name ? 'rotate-90' : ''
                          }`}
                        />
                      )}
                    </button>
                    {link.dropdown && mobileActiveDropdown === link.name && (
                      <div className="pl-8 space-y-2">
                        {link.dropdown.map((item) => (
                          <button
                            key={item.name}
                            onClick={() => handleNavigation(item.href)}
                            className="block w-full text-left text-gray-400 hover:text-white py-2"
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
